<template>
    <div class="basic-dropdown">
        <div
            class="bd-display-value"
            tabindex="0"
            :style="style"
            @click="handleClickOptions"
            @focusin="handleFocusOptions"
            ref="displayValue"
        >
            <slot name="selected" v-if="modelValue">
                {{ modelValue.name || modelValue }}
            </slot>

            <i
                class="fa fa-chevron-down"
                :style="showOptions ? { transform: 'rotate(180deg)' } : {}"
            />
        </div>
        <span
            class="bd-error-message"
            v-if="
                (errorMessage || meta.valid || errorContent) &&
                meta.path !== 'new-username'
            "
        >
            {{ errorMessage }}
        </span>
        <Teleport to="body">
            <Transition name="fade">
                <div
                    class="bd-options-container"
                    v-if="showOptions"
                    :style="optionsPosition"
                    ref="options"
                >
                    <div
                        v-if="options.length > 0"
                        class="bd-options-list-wrapper"
                    >
                        <div
                            v-for="item in options"
                            :key="item.name"
                            class="bd-option"
                            :class="{
                                'bd-selected':
                                    isTheSameObject(modelValue, item) &&
                                    !disabled.includes(item.id),
                                disabled: disabled.includes(item.id),
                            }"
                            @click="onInput(item)"
                            :data-value="item.value"
                        >
                            <slot name="options" v-bind="item">
                                {{ item.name }}
                            </slot>
                        </div>
                    </div>
                    <div
                        class="bd-separator-wrapper"
                        v-if="$slots.subcontent && options.length > 0"
                    >
                        <div class="bd-separator" />
                    </div>
                    <div class="bd-option" v-if="$slots.subcontent">
                        <slot name="subcontent" />
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>
</template>

<script>
import { useField } from "vee-validate"
import { watch, nextTick } from "vue"

export default {
    name: "BasicDropdown",
    props: {
        options: {
            type: Array,
            required: true,
        },
        displayType: {
            type: String,
            default: "text",
        },
        modelValue: {
            type: String,
            default: null,
        },
        neededPlaceholder: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Function,
        },
        name: {
            type: String,
            required: true,
        },
        alignement: {
            type: String,
            default: "left",
        },
        disabled: {
            type: Array,
            default: () => [],
        },
        style: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            focusState: false,
            showOptions: false,
            optionsPosition: {},
        }
    },
    emits: ["update:modelValue", "errorMessage"],
    computed: {
        isModelValueIsInOptions() {
            return this.options.some((item) => {
                return this.isTheSameObject(this.modelValue, item)
            })
        },
    },
    methods: {
        isTheSameObject(a, b) {
            return JSON.stringify(a) === JSON.stringify(b)
        },

        handleClickOptions() {
            if (!this.focusState) {
                this.toggleOptions()
            }
            this.focusState = false
        },

        handleFocusOptions() {
            this.focusState = true
            this.toggleOptions()
        },
        toggleOptions() {
            this.showOptions = !this.showOptions

            if (this.showOptions) {
                // Attendre le rendu du DOM avant de calculer la position
                nextTick(() => {
                    this.calculatePosition()
                })
                // Ajouter les écouteurs pour mettre à jour la position si nécessaire
                window.addEventListener("scroll", this.calculatePosition, true)
                window.addEventListener("resize", this.calculatePosition)
            } else {
                // Retirer les écouteurs lorsque les options sont fermées
                window.removeEventListener(
                    "scroll",
                    this.calculatePosition,
                    true
                )
                window.removeEventListener("resize", this.calculatePosition)
            }
        },

        closeOption() {
            this.showOptions = false
        },

        choosePosition(alignement) {
            switch (alignement) {
                case "left":
                    return "left: 0"
                case "center":
                    return "left: 50%; transform: translateX(-50%)"
                case "right":
                    return "right: 0"
            }
        },

        calculatePosition() {
            const displayValueRect =
                this.$refs.displayValue.getBoundingClientRect()

            this.optionsPosition = {
                position: "absolute",
                top: `${displayValueRect.bottom + window.scrollY}px`,
                left: `${displayValueRect.left + window.scrollX}px`,
                width: `${displayValueRect.width}px`,
                zIndex: 1000, // Assure-toi que c'est supérieur aux autres z-index
            }

            // Gérer l'alignement si nécessaire
            if (this.alignement === "center") {
                this.optionsPosition.left = `${
                    displayValueRect.left +
                    window.scrollX +
                    displayValueRect.width / 2
                }px`
                this.optionsPosition.transform = "translateX(-50%)"
            } else if (this.alignement === "right") {
                this.optionsPosition.left = `${
                    displayValueRect.right + window.scrollX
                }px`
                this.optionsPosition.transform = "translateX(-100%)"
            }

            //Changer la position si la fenêtre est trop petite en hauteur
            if (
                displayValueRect.bottom +
                    this.$refs.options.getBoundingClientRect().height >
                window.innerHeight
            ) {
                this.optionsPosition.top = `${
                    displayValueRect.top -
                    this.$refs.options.getBoundingClientRect().height
                }px`
            }
        },

        onInput(item) {
            let itemIsDisabled = []

            itemIsDisabled = this.disabled.filter(
                (element) => element == item.id
            )

            if (itemIsDisabled.length === 0) {
                this.$emit("update:modelValue", item)
                this.closeOption()
            }
        },
    },
    mounted() {
        if (!this.isModelValueIsInOptions && this.neededPlaceholder) {
            let defaultValue = this.options[0]
            this.$emit("update:modelValue", defaultValue)
        }
        document.addEventListener("click", (event) => {
            if (!this.$el.contains(event.target)) {
                this.closeOption()
            }
        })
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleOutsideClick)
        window.removeEventListener("scroll", this.calculatePosition, true)
        window.removeEventListener("resize", this.calculatePosition)
    },
    setup(props, { emit }) {
        const {
            value: inputValue,
            errorMessage,
            handleChange,
            handleBlur,
            meta,
        } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        })

        emit("errorMessage", errorMessage)

        return {
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        }
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>

<style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";

.basic-dropdown {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible;
}

.bd-display-value {
    z-index: 1;
    min-width: 40px;
    min-height: 46px;
    height: 100%;
    color: $blue-neutral;
    background-color: $light-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 0 1em;
    gap: 0.5em;
    transition: 0.2s all;

    p {
        margin: 0;
    }
}

.bd-display-value i {
    transition: 0.2s all;
}

.bd-display-value:hover {
    cursor: pointer;
}

.bd-display-value:focus {
    transition: 0.2s all;
    box-shadow: inset 0 0 0 0.25rem rgba($blue-medium-pale, $alpha: 1);
}

.bd-options-container {
    width: 100%;
    min-width: max-content;
    position: absolute;
    bottom: -1;
    z-index: 100;
    padding: 0.5em;
    word-wrap: break-word;
    box-shadow: rgba($blue-neutral, $alpha: 0.2) 5px 5px 20px;
    background-color: $white;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $light-grey;
}

.bd-options-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}

.bd-selected {
    background-color: $light-grey;
}

.bd-selected-value {
    background-color: $light-grey;
}

.bd-option {
    padding: 0.5em 1em;
    border-radius: 5px;
    font-family: $font_avenir_roman;
    font-size: $normal;
    color: $blue-neutral;
    transition: 0.1s all;

    p {
        margin: 0;
    }
}

.bd-option:hover {
    cursor: pointer;
    background-color: $light-grey;
    transition: 0.1s all;
}

.bd-disabled {
    opacity: 0.5;
}

.bd-subcontent {
    border-top: solid 1px black;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-family: $font_avenir_roman;
    font-size: $small;
    color: $blue-neutral;
    transition: 0.1s all;

    p {
        margin: 0;
    }
}

.bd-separator-wrapper {
    padding: 0.2em 0.5em;
}

.bd-separator {
    border-top: solid 1px $light-grey;
}

.bd-subcontent:hover {
    cursor: pointer;
    background-color: $light-grey;
    transition: 0.1s all;
}

.bd-disabled:hover {
    cursor: not-allowed;
    background-color: $white;
}
</style>

<!-- <style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";

.bd-options-container {
    width: 100%;
    min-width: max-content;
    position: absolute;
    bottom: -1;
    z-index: 100;
    padding: 0.5em;
    word-wrap: break-word;
    box-shadow: rgba($blue-neutral, $alpha: 0.2) 5px 5px 20px;
    background-color: $white;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $light-grey;

    .selected {
        background-color: $light-grey;
    }

    .option {
        padding: 0.5em 1em;
        border-radius: 5px;
        font-family: $font_avenir_roman;
        font-size: $small;
        color: $blue-neutral;
        transition: 0.1s all;

        &:hover {
            cursor: pointer;
            background-color: $light-grey;
            transition: 0.1s all;
        }
    }

    .disabled {
        opacity: 0.5;

        &:hover {
            cursor: not-allowed;
            background-color: $white;
        }
    }
}
</style> -->
