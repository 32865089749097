<template>
    <div class="module-replace-main-container">
        <div class="module-replace-container">
            <div class="image-container">
                <div class="image-wrapper">
                    <img
                        :src="
                            require('@/assets/images/infos/antenne_modules.svg')
                        "
                        alt=""
                    />
                </div>
            </div>
            <div class="text-container">
                <div class="text-wrapper">
                    <BasicDropdown
                        v-model="country"
                        :options="countries"
                        :neededPlaceholder="true"
                        name="countries"
                        @update:modelValue="updateCountry($event)"
                        class="countrySelect"
                    >
                        <template #selected v-if="country.value">
                            <div class="selected-value">
                                <div class="image-wrapper">
                                    <img
                                        :src="`https://flagcdn.com/w320/${country.value.toLowerCase()}.png`"
                                        alt=""
                                    />
                                </div>
                                <p>{{ country.name }}</p>
                            </div>
                        </template>
                        <template #options="{ name, value }">
                            <div class="options-flag">
                                <div class="image-wrapper">
                                    <img
                                        :src="`https://flagcdn.com/w320/${value.toLowerCase()}.png`"
                                        alt=""
                                    />
                                </div>
                                <p>{{ name }}</p>
                            </div>
                        </template>
                    </BasicDropdown>
                    <div class="text" v-if="country.value">
                        <h2 v-if="needSecondText">
                            {{
                                $t("module.infos.ended2G.languageVersion", 1, {
                                    locale: getFirstLang,
                                })
                            }}
                        </h2>
                        <p>
                            {{
                                $t("module.infos.description", 1, {
                                    locale: getFirstLang,
                                })
                            }}
                        </p>
                        <h1>
                            {{
                                $t("module.infos.endedContract.title", 1, {
                                    locale: getFirstLang,
                                })
                            }}
                        </h1>
                        <p>
                            {{
                                $t(
                                    "module.infos.endedContract.description",
                                    1,
                                    { locale: getFirstLang }
                                )
                            }}
                        </p>
                        <h1>
                            {{
                                $t("module.infos.ended2G.title", 1, {
                                    locale: getFirstLang,
                                })
                            }}
                        </h1>
                        <p v-html="sanitizeEnded2GDescription"></p>
                        <p>
                            {{
                                $t("module.infos.ended2G.changeCountry", 1, {
                                    locale: getFirstLang,
                                })
                            }}
                        </p>
                    </div>
                    <div class="separator" v-if="needSecondText"></div>
                    <div v-if="needSecondText" class="text">
                        <h2>
                            {{
                                $t("module.infos.ended2G.languageVersion", 1, {
                                    locale: getSecondLang,
                                })
                            }}
                        </h2>
                        <p>
                            {{
                                $t("module.infos.description", 1, {
                                    locale: getSecondLang,
                                })
                            }}
                        </p>
                        <h1>
                            {{
                                $t("module.infos.endedContract.title", 1, {
                                    locale: getSecondLang,
                                })
                            }}
                        </h1>
                        <p>
                            {{
                                $t(
                                    "module.infos.endedContract.description",
                                    1,
                                    { locale: getSecondLang }
                                )
                            }}
                        </p>
                        <h1>
                            {{
                                $t("module.infos.ended2G.title", 1, {
                                    locale: getSecondLang,
                                })
                            }}
                        </h1>
                        <p>
                            {{
                                $t("module.infos.ended2G.description", 1, {
                                    locale: getSecondLang,
                                })
                            }}
                        </p>
                        <p>
                            {{
                                $t("module.infos.ended2G.changeCountry", 1, {
                                    locale: getSecondLang,
                                })
                            }}
                        </p>
                    </div>
                    <div class="text">
                        <p>© Intratone {{ year }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DOMPurify from "dompurify"
import BasicDropdown from "@/components/basic/BasicDropdown.vue"

export default {
    components: {
        BasicDropdown,
    },
    data() {
        return {
            country: {},
            countries: [
                {
                    name: "France",
                    value: "FR",
                },
                {
                    name: "United Kingdom",
                    value: "GB",
                },
                {
                    name: "Suisse / Schweiz",
                    value: "CH",
                },
                {
                    name: "Denmark",
                    value: "DK",
                },
                {
                    name: "Suomi",
                    value: "FI",
                },
                {
                    name: "Deutschland",
                    value: "DE",
                },
                {
                    name: "Belgique / België",
                    value: "BE",
                },
                {
                    name: "Luxembourg / Luxemburg",
                    value: "LU",
                },
                {
                    name: "Nederland",
                    value: "NL",
                },
                {
                    name: "Norge",
                    value: "NO",
                },
                {
                    name: "Sveridge",
                    value: "SE",
                },
                {
                    name: "España",
                    value: "ES",
                },
            ],
            year: new Date().getFullYear(),
        }
    },

    computed: {
        needSecondText() {
            switch (this.country.value) {
                case "BE":
                case "LU":
                case "CH":
                    return true

                default:
                    return false
            }
        },

        getFirstLang() {
            switch (this.country.value) {
                case "BE":
                    return "be-fr"
                case "LU":
                    return "lu-fr"
                case "CH":
                    return "ch-fr"
                case "GB":
                    return "en"

                default:
                    return this.country.value.toLowerCase()
            }
        },

        getSecondLang() {
            switch (this.country.value) {
                case "BE":
                    return "fl"
                case "LU":
                    return "lu-de"
                case "CH":
                    return "ch-de"

                default:
                    return "en"
            }
        },

        // Only for UK => No needed to have secondLang variable
        sanitizeEnded2GDescription() {
            return DOMPurify.sanitize(
                this.$t("module.infos.ended2G.description", 1, {
                    locale: this.getFirstLang,
                })
            )
        },
    },

    methods: {
        initializeLanguage() {
            if (this.$i18n.locale) {
                const option = this.countries.find((item) => {
                    if (this.$i18n.locale.slice(0, 2) === "en") {
                        return item.value.toLowerCase() === "gb"
                    } else {
                        return (
                            this.$i18n.locale.slice(0, 2) ===
                            item.value.toLowerCase()
                        )
                    }
                })

                this.updateCountry(option)
            }
        },

        updateCountry(newData) {
            this.country = newData
        },
    },

    created() {
        this.initializeLanguage()
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

p,
h1,
h2 {
    margin: 0;
}
.module-replace-main-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .module-replace-container {
        width: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: center;
        gap: 2em;

        .image-container {
            width: max-content;
            height: 90%;
            display: flex;
            justify-content: right;
            padding-top: 3em;

            @media all and (max-width: 768px) {
                display: none;
            }

            .image-wrapper {
                max-height: 600px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .text-container {
            max-width: 500px;
            overflow-y: auto;
            height: calc(100svh - 2em - 2em - 22px);

            @media all and (max-width: 768px) {
                max-width: 100%;
            }

            .text-wrapper {
                padding: 3em;
                display: flex;
                flex-direction: column;
                gap: 2em;

                @media all and (max-width: 1024px) {
                    padding: 3em 1em;
                }

                .countrySelect {
                    width: 250px;
                }

                .selected-value {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5em;

                    .image-wrapper {
                        width: 20px;
                        height: 15px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 2em;

                    h2 {
                        font-family: $font_avenir_heavy;
                        font-size: $semiBig;
                    }

                    h1 {
                        font-size: $veryBig;
                        font-family: $font_avenir_black;
                    }

                    p {
                        font-size: $normal;
                    }
                }
            }

            .separator {
                width: 100%;
                border-bottom: 1px solid $blue-neutral;
            }
        }
    }
}
</style>

<style lang="scss">
.options-flag {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .image-wrapper {
        width: 20px;
        height: 15px;
        border-radius: 3px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }
}
</style>
